import { withRouter } from "react-router-dom";
import Page from "../../../components/ubold/organisms/Page";

function CPage() {
  return (
    <Page title="(DEPRECATED) Service Request Order List">
      <p>
        <br />
        Untuk melihat Service Request Order List dapat dilakukan di
        metabase dengan cara:
        <br />
        <br />
        <ol>
          <li>
            Navigate to{" "}
            <a href="https://metabase.rukita.co/dashboard/102-dashboard-service-request?building_name=&building_id=&service_request=&order_number=&service_request_created=&sub_case_category=&tenant_name=">
              https://metabase.rukita.co/dashboard/102-dashboard-service-request
            </a>
          </li>
        </ol>
        <br />
        NB: Jika terdapat kendala dalam pengoperasiannya, dapat
        menghubungi tim product (roberta@rukita.co)
      </p>
    </Page>
  );
}

export default withRouter(CPage);
