import { withRouter } from "react-router-dom";
import Page from "../../../components/ubold/organisms/Page";

function CaseCategoryPage() {
  return (
    <Page title="(DEPRECATED) Case Category Creation">
      <p>
        <br />
        Untuk manage Case Category Creation dapat dilakukan di retool
        dengan cara:
        <br />
        <br />
        <ol>
          <li>
            Navigate to{" "}
            <a href="https://rukita.retool.com/p/service-detail?serviceId=1">
              https://rukita.retool.com/p/service-detail?serviceId=1
            </a>
          </li>
        </ol>
        <br />
        NB: Jika terdapat kendala dalam pengoperasiannya, dapat
        menghubungi tim product (roberta@rukita.co)
      </p>
    </Page>
  );
}

export default withRouter(CaseCategoryPage);
